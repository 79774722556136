export const folder = [
  "--------",
  "Wind",
  "Solar",
  "Biomass",
  "HydroPower",
  "Hydrogen",
  "Renewables",
  "Geothermal",
  "Energy Efficiency",
  "Energy Prices",
  "LNG",
];
