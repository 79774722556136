import { useEffect, useState } from "react";
import Input from "./components/Input";
import Selector from "./components/Selector";
import TextBox from "./components/TextBox";
import { query } from "./mocks/queryMock";
import { folder } from "./mocks/folderMock";
import { textBoxes } from "./mocks/textboxMock";
import { titleArr } from "./mocks/titleMock";
import { getPost, getCountries, PostNews } from "./services/api";
import { useParams } from "react-router-dom";
import { postMock } from "./mocks/postMock";

function App() {
  const [state, setState] = useState(null);
  const [countries, setCountries] = useState([]);
  const { nid } = useParams();
  const [textRef, setTextRef] = useState(false);
  const [titleRef, setTitleRef] = useState(false);
  const [another, setAnother] = useState(false);

  let newstitle = localStorage.getItem("newstitle");
  let newsid = localStorage.getItem("newsid");

  if (nid !== "0") {
    newstitle = null;
  }

  useEffect(() => {
    async function startMe() {
      let post = null;
      if (nid !== "0") {
        post = await getPost(nid);
      } else {
        post = postMock;
      }
      let cc = await getCountries();
      setState(post);
      setCountries(cc);
    }
    if (nid) startMe();
  }, [nid]);

  useEffect(() => {}, [textRef]);

  function handleSubmit(e) {
    e.preventDefault();

    // console.log({
    //   ...state,
    //   folder:
    //     state.folder !== "--------" && state.folder ? "/" + state.folder : null,
    //   query: state.query === "--------" ? null : state.query,
    //   countries: state.countries.map((i) => i.code).join(","),
    //   validated_text: state.text,
    // });
    let auxStr = state.text.replaceAll("\n\n\n", "\r\n\r");
    auxStr = auxStr.replaceAll("\n\n", "\r\n\r");
    let prepState = {
      ...state,
      folder:
        state.folder !== "--------" && state.folder ? "/" + state.folder : null,
      query: state.query === "--------" ? null : state.query,
      countries: state.countries.map((i) => i.code).join(","),
      validated_text: auxStr,
    };
    PostNews(prepState, another);
  }

  function handleChange(e) {
    if (e.target.id === "countries") {
      let options = e.target.options;
      let val = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          val.push(
            countries.filter((item) => item.code === options[i].value)[0]
          );
        }
      }
      setState((state) => ({ ...state, [e.target.id]: [...val] }));
    } else {
      setState((state) => ({ ...state, [e.target.id]: e.target.value }));
    }
  }

  function handleTextGo() {
    setState((state) => ({
      ...state,
      validated_en: null,
      validated_fr: null,
      validated_de: null,
      validated_nl: null,
      validated_it: null,
      validated_sp: null,
      validated_pl: null,
    }));
    setTextRef(!textRef);
  }

  function handleTitleGo() {
    setState((state) => ({
      ...state,
      title_en: null,
      title_fr: null,
      title_de: null,
      title_nl: null,
      title_it: null,
      title_sp: null,
      title_pl: null,
    }));
    setTitleRef(!titleRef);
  }

  if (!state || !countries.length) return;

  return (
    <div className="w-full flex flex-col">
      <div className="bg-blue-500 text-white flex w-full justify-between px-6 py-4">
        <div>
          <a href="/admin" className="text-2xl text-yellow-200 ml-4">
            Energy News Admin Dashboard
          </a>
        </div>
        <div className="flex items-center">
          <a href="logout" className="p-2">
            Log Out
          </a>
        </div>
      </div>
      <div className="flex w-full bg-blue-300 text-white justify-start py-3 px-6">
        <div>
          <a href="/admin" className="inline ml-4 underline">
            Home
          </a>
          <span className="px-1"> > </span>
          <a href="/admin/posts/" className="inline underline">
            Posts
          </a>
          <span className="px-1"> > </span>
          <a href="/admin/posts/post/" className="inline underline">
            News
          </a>
        </div>
      </div>
      {newstitle && newstitle !== "undefined" ? (
        <div className="flex w-full bg-green-200 py-3 px-6">
          <p className="mx-4 my-0">
            The Post
            <a
              className="text-blue-500 underline"
              href={"/newpostedit/" + newsid}
            >
              {newstitle}
            </a>
            was added successfully.
          </p>
        </div>
      ) : null}
      <div className="m-6">
        <p className="text-gray-500 text-2xl px-4">Edit Post</p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Selector
            value={["Manual", "Non Commodity"]}
            text={["Manual", "Non Commodity"]}
            forID={"data_source"}
            handleChange={handleChange}
            selected={state?.data_source}
            title={"Data source:"}
          />
          <Selector
            value={query}
            text={query}
            handleChange={handleChange}
            selected={state?.query || ""}
            forID={"query"}
            title={"Query:"}
          />
          <div className="customFlex">
            <div className="flex items-center">
              <label
                htmlFor="hasPrice"
                className="w-[220px] px-4 font-semibold cursor-pointer"
              >
                Has Prices
              </label>
              <input
                type="checkbox"
                id="hasPrice"
                checked={state.has_price}
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    has_price: !state.has_price,
                  }))
                }
              />
            </div>
          </div>

          <div className="customFlex">
            <div className="flex items-center">
              <label
                htmlFor="hasEditor"
                className="w-[220px] px-4 font-semibold cursor-pointer"
              >
                Editor Choice
              </label>
              <input
                type="checkbox"
                id="hasEditor"
                checked={state.editor_choice}
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    editor_choice: !state.editor_choice,
                  }))
                }
              />
            </div>
          </div>
          <Selector
            value={folder}
            text={folder}
            handleChange={handleChange}
            selected={state?.folder || ""}
            forID={"folder"}
            title={"Folder:"}
          />
          <Input
            title={"Url:"}
            forID={"url"}
            handleChange={handleChange}
            val={state?.url}
          />
          <Input
            title={"Source:"}
            forID={"source"}
            handleChange={handleChange}
            val={state?.source}
          />
          <Input
            title={"Posted:"}
            handleChange={handleChange}
            forID={"posted"}
            val={state?.posted}
          />
          <i className="tips">
            Use key "<b>b</b>" for <b>bold</b> and <b>tilda</b> key to insert a
            image
          </i>
          <div className="customWrapper">
            {textBoxes.map((i) => (
              <TextBox
                title={i.title}
                forID={i.forID}
                key={i.forID}
                trans={i.trans}
                handleChange={handleChange}
                val={state?.[i.forID] || ""}
                orgVal={state?.text}
                para={i.para}
                handleTextGo={handleTextGo}
                refresh={textRef}
              />
            ))}
          </div>
          <Selector
            value={countries.map((i) => i.code)}
            text={countries.map((i) => i.name)}
            handleChange={handleChange}
            selected={state?.countries.map((i) => i.code)}
            forID={"countries"}
            title={"Countries:"}
          />
          {titleArr.map((i) => (
            <Input
              title={i.title}
              forID={i.forID}
              key={i.forID}
              extra={i.extra}
              trans={i.lang}
              handleChange={handleChange}
              handleTitleGo={handleTitleGo}
              refresh={titleRef}
              val={state?.[i.forID] || ""}
              orgVal={state?.title}
            />
          ))}
          <Input
            title={"FotoURL:"}
            forID={"fotourl"}
            handleChange={handleChange}
            val={state?.fotourl}
          />

          <div className="flex justify-center">
            <button
              className="custombtn mt-5 mr-4"
              type="submit"
              onClick={() => setAnother(true)}
            >
              Save and add another
            </button>
            <button className="custombtn mt-5" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;
